import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback } from "react";
import { useTheme } from "@mui/material";

type TimeRangePickerProps = {
  startName: string;
  endName: string;
  control: any;
  required?: boolean;
};

const TimeRangePicker = ({
  startName,
  endName,
  control,
  required = true,
}: TimeRangePickerProps) => {
  const theme = useTheme();
  const { setValue, getValues } = useFormContext();

  const onAcceptStart = useCallback(
    (value: Dayjs) => {
      // Get the date from eventDate but ignore the time
      let eventDate = getValues("eventDate");
      let eventDateWithoutTime = dayjs(eventDate).startOf("day");

      // Combine the date from eventDate and the time from value
      let newStartTime = eventDateWithoutTime
        .hour(value.hour())
        .minute(Math.round(value.minute() / 30) * 30);

      // Get current time rounded to the nearest 30 minutes
      const currentTime = dayjs().minute(
        Math.round(dayjs().minute() / 30) * 30
      );

      // If newStartTime is less than the currentTime, round it to the currentTime
      if (newStartTime.isBefore(currentTime)) {
        newStartTime = currentTime;
      }

      let endDateTime = getValues("endDateTime");

      // If newStartTime is >= endDateTime, set endDateTime to be 30 minutes after newStartTime
      if (
        !endDateTime ||
        newStartTime.isAfter(dayjs(endDateTime)) ||
        newStartTime.isSame(dayjs(endDateTime))
      ) {
        endDateTime = newStartTime.add(30, "minute");
        setValue("endDateTime", endDateTime, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      // Set startDateTime to newStartTime
      setValue("startDateTime", newStartTime, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [getValues, setValue]
  );

  const onAcceptEnd = useCallback(
    (value: Dayjs) => {
      // Get the date from eventDate but ignore the time
      let eventDate = getValues("eventDate");
      let eventDateWithoutTime = dayjs(eventDate).startOf("day");

      // Combine the date from eventDate and the time from value
      let newEndTime = eventDateWithoutTime
        .hour(value.hour())
        .minute(Math.round(value.minute() / 30) * 30);

      let startDateTime = getValues("startDateTime");

      // If newEndTime is <= startDateTime, set newEndTime to be 30 minutes after startDateTime
      if (
        !startDateTime ||
        newEndTime.isBefore(dayjs(startDateTime)) ||
        newEndTime.isSame(dayjs(startDateTime))
      ) {
        newEndTime = dayjs(startDateTime).add(30, "minute");
      }

      setValue("endDateTime", newEndTime, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [getValues, setValue]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={startName}
        control={control}
        rules={{
          required: {
            value: required,
            message: `Please select a start time *`,
          },
          validate: {
            isBeforeEndTime: (value) =>
              dayjs(value).isBefore(dayjs(getValues(endName))) ||
              "Start time must be before end time",
            validMinutes: (value) => {
              const minutes = dayjs(value).minute();
              return (
                [0, 15, 30, 45].includes(minutes) ||
                "Minute must be 00, 15, 30, or 45"
              );
            },
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            onAccept={onAcceptStart}
            label="Start Time"
            {...field}
            sx={{
              width: "100%",
              [theme.breakpoints.up("desktop")]: {
                width: "33%",
              },
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
              },
            }}
          />
        )}
      />
      <Controller
        name={endName}
        control={control}
        rules={{
          required: {
            value: required,
            message: `Please select an end time *`,
          },
          validate: {
            isAfterStartTime: (value) =>
              dayjs(value).isAfter(dayjs(getValues(startName))) ||
              "End time must be after start time",
            validMinutes: (value) => {
              const minutes = dayjs(value).minute();
              return (
                [0, 15, 30, 45].includes(minutes) ||
                "Minute must be 00, 15, 30, or 45"
              );
            },
          },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <TimePicker
              onAccept={onAcceptEnd}
              label="End Time"
              {...field}
              sx={{
                width: "100%",
                [theme.breakpoints.up("desktop")]: {
                  width: "33%",
                },
              }}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error?.message,
                },
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default TimeRangePicker;
