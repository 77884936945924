import * as Styled from "./ProtectedRoute.styles";

import { AuthState, UserState } from "../../types/redux";
import { useEffect, useState } from "react";

import APIError from "../APIError/APIError";
import { CircularProgress } from "@mui/material";
import EnvironmentIndicator from "../EnvironmentIndicator/EnvironmentIndicator";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import LiveEventIndicator from "../LiveEventIndicator/LiveEventIndicator";
import { PageContainer } from "../PageContainer/PageContainer.styles";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getUserInfo } from "../../store/actions/userActions";
import { useAppDispatch } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface ProtectedRouteProps {
  redirect?: string;
  children: any;
  showEnvironmentIndicator?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
  showLiveEventIndicator?: boolean;
}

const ProtectedRoute = ({
  children,
  showEnvironmentIndicator = process.env.REACT_APP_ENVIRONMENT !== "prod",
  showHeader = true,
  showFooter = true,
  showLiveEventIndicator = true,
}: ProtectedRouteProps) => {
  const authState: AuthState = useSelector((state: any) => state.auth);
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    if (!authState.isAuthorized) {
      navigate("/", { state: { from: location.pathname + location.search } });
    }
    dispatch(getUserInfo());
    dispatch(getAllEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isAuthorized]);

  useEffect(() => {
    if (!userState.loading && userState.success && userState.email !== "") {
      dispatch(getAllUserEvents(userState.email));
    }
  }, [userState.loading, userState.success, userState.email, dispatch]);

  useEffect(() => {
    if (
      (location.pathname.startsWith("/edit") ||
        location.pathname.startsWith("/manage") ||
        location.pathname.startsWith("/create")) &&
      userState.role !== "ADMIN" &&
      authState.isAuthorized &&
      !userState.loading &&
      userState.success
    ) {
      navigate("/events");
    } else {
      setIsUserAdmin(true);
    }
  }, [
    location,
    userState.role,
    authState.isAuthorized,
    userState.loading,
    userState.success,
    navigate,
  ]);

  if (!isUserAdmin || authState.error) {
    return (
      <PageContainer>
        <Styled.LoaderContainer>
          <CircularProgress />
        </Styled.LoaderContainer>
      </PageContainer>
    );
  }

  if (userState.error) {
    return (
      <APIError
        onClick={() => {
          if (authState.isAuthorized) {
            dispatch(getUserInfo());
          }
        }}
      />
    );
  }

  return (
    <>
      {!userState.loading &&
      userState.success &&
      !userState.error &&
      authState.isAuthorized ? (
        <>
          {showHeader && (
            <div id="header-container">
              <Header />
            </div>
          )}
          {showLiveEventIndicator && <LiveEventIndicator />}
          {children}
          {showEnvironmentIndicator && <EnvironmentIndicator />}
          {showFooter && <Footer />}
        </>
      ) : (
        <PageContainer>
          <Styled.LoaderContainer>
            <CircularProgress />
          </Styled.LoaderContainer>
        </PageContainer>
      )}
    </>
  );
};

export default ProtectedRoute;
