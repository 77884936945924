import {
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
  Radio,
  RadioProps,
  useTheme,
} from "@mui/material";

import { Controller } from "react-hook-form";

type RadioGroupProps = {
  name: string;
  control: any;
  label: string;
  required?: boolean;
  options: { label: string; value: string }[];
  row?: boolean | undefined;
};

const RadioGroup = ({
  name,
  control,
  label,
  required = false,
  options,
  row,
}: RadioProps & RadioGroupProps) => {
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: `${label} is required` },
      }}
      render={({ field }) => (
        <MUIRadioGroup
          {...field}
          row={row}
          sx={{ marginBottom: theme.spacing(1) }}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </MUIRadioGroup>
      )}
    />
  );
};

export default RadioGroup;
