export const COGNITO_AUTH_ENDPOINT = (state: string) => {
  const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID || "";
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN_NAME || "";
  const redirectUrl = process.env.REACT_APP_COGNITO_REDIRECT_URL || "";
  const identityProvider =
    process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER || "";
  return `https://${cognitoDomain}.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${cognitoClientId}&redirect_uri=${redirectUrl}&state=${encodeURIComponent(
    state
  )}&identity_provider=${identityProvider}`;
};

export const COGNITO_TOKEN_ENDPOINT = (
  cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN_NAME || ""
) => {
  return `https://${cognitoDomain}.auth.us-east-1.amazoncognito.com/oauth2/token`;
};

export const COGNITO_USER_INFO_ENDPOINT = (
  cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN_NAME || ""
) => {
  return `https://${cognitoDomain}.auth.us-east-1.amazoncognito.com/oauth2/userInfo`;
};

export const CREATE_EVENT = () => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events`;
};

export const EDIT_EVENT = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events/${eventId}`;
};

export const GET_ALL_EVENTS = () => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events`;
};

export const GET_ALL_USER_EVENTS = (userEmail: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}${userEmail}/events`;
};

export const GET_ATTENDESS = (eventId: string) => {
  return `${
    process.env.REACT_APP_API_BASE_URL || ""
  }/events/${eventId}/attendees`;
};

export const GET_EVENT = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events/${eventId}`;
};

export const GET_EVENT_METRICS = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events/${eventId}/metrics`;
};

export const GET_EVENT_IMAGES = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events/${eventId}/images`;
};

export const POST_EVENT_IMAGE = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events/${eventId}/images`;
};

export const GET_SIGNED_URL = (eventId: string) => {
  return `${
    process.env.REACT_APP_API_BASE_URL || ""
  }/events/${eventId}/images/{imageName}/sign`;
};

export const GET_EVENT_SIGNUPS = (eventId: string) => {
  return `${
    process.env.REACT_APP_API_BASE_URL || ""
  }events/${eventId}/sign-ups`;
};

export const GET_EVENT_SIGNUP_FOR_USER = (
  eventId: string,
  participantEmail: string
) => {
  return `${
    process.env.REACT_APP_API_BASE_URL || ""
  }events/${eventId}/sign-up/${participantEmail}`;
};

export const JOIN_EVENT = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}/events/${eventId}/join`;
};

export const SEARCH_EVENTS = (
  term: string,
  status: "ACTIVE" | "COMPLETE" | "ALL"
) => {
  const params = new URLSearchParams({ term, status });
  return `${
    process.env.REACT_APP_API_BASE_URL || ""
  }events/search?${params.toString()}`;
};

export const SIGNUP_USER_TO_EVENT = (eventId: string) => {
  return `${process.env.REACT_APP_API_BASE_URL || ""}events/${eventId}/sign-up`;
};

export const UNSIGNUP_USER_TO_EVENT = (eventId: string) => {
  return `${
    process.env.REACT_APP_API_BASE_URL || ""
  }events/${eventId}/unsign-up`;
};
