import * as Styled from "./CreateEventPage.styles";

import {
  APIError,
  Checkbox,
  ChipListInput,
  DatePickerInput,
  FileInput,
  FormBuilder,
  Input,
  PageContainer,
  RadioGroup,
  TimeRangePicker,
} from "../../components";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";

import { CREATE_EVENT } from "../../services/endpoints";
import { ClearFormModal } from "../../modals";
import { NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import { UserState } from "../../types/redux";
import axiosAPI from "../../services/axios";
import { combineDayJSToUnix } from "../../utils/utils";
import formOptions from "./formOptions";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import imageCompression from "browser-image-compression";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

type CreateEventForm = {
  signUpCreator: boolean;
  title: string;
  description: string;
  eventDate?: Dayjs | null;
  startDateTime?: Dayjs | null;
  endDateTime?: Dayjs | null;
  dressCode: string;
  admissionCost: number;
  eventImageFile: File | null;
  format: string;
  businessAreaTags: string[];
  groupTags: string[];
  teamTags: string[];
  location: string;
  transportationParkingDetails: string;
  primaryContacts: string[];
  includeTeamsLink: string;
  teamsLink?: string;
  feedbackLink: string;
  includeSignUpSurvey: string;
  signUpSurvey: { type: string; question: string }[];
};

const CreateEventPage = () => {
  const currentTime = dayjs();
  const roundToNearest30Minutes = (dateTime: Dayjs) => {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.round(minutes / 30) * 30;
    return dateTime.startOf("hour").add(roundedMinutes, "minute");
  };

  const userInfo = useSelector((state: any) => {
    const userState = state.user as UserState;
    return userState;
  });

  const formMethods = useForm({
    defaultValues: {
      title: "",
      description: "",
      eventDate: dayjs(),
      startDateTime: roundToNearest30Minutes(currentTime),
      endDateTime: roundToNearest30Minutes(currentTime).add(30, "minute"),
      dressCode: "",
      admissionCost: 0,
      format: "",
      eventImageFile: null,
      businessAreaTags: [],
      groupTags: [],
      teamTags: [],
      signUpCreator: true,
      location: "399 Boylston St #1000, Boston, MA",
      transportationParkingDetails: "",
      primaryContacts: [userInfo.name],
      includeTeamsLink: "no",
      teamsLink: "",
      feedbackLink: "",
      includeSignUpSurvey: "yes",
      signUpSurvey: [],
    },
    mode: "onChange",
  });
  const [showClearFormModal, setShowClearFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createEventError, setCreateEventError] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const includeTeamsLink = formMethods.watch("includeTeamsLink");
  const includeSignUpSurvey = formMethods.watch("includeSignUpSurvey");
  const { errors } = formMethods.formState;
  const hasErrors = Object.keys(errors).length > 0;

  const createEvent = async (newEventObj: any) => {
    await axiosAPI
      .post(CREATE_EVENT(), newEventObj)
      .then((response: any) => {
        dispatch(getAllEvents());
        dispatch(getAllUserEvents(userInfo.email));
        dispatch({
          type: NEW_EVENT_CREATED,
          payload: { id: response.data.result.eventId },
        });
        setLoading(false);
        window.scroll(0, 0);
        navigate("/events");
      })
      .catch((error) => {
        setLoading(false);
        setCreateEventError(true);
      });
  };

  const getEventObj = (data: any, eventHero: string) => ({
    ...data,
    startDateTime: combineDayJSToUnix(
      data.eventDate as Dayjs,
      data.startDateTime as Dayjs
    ),
    eventHero,
    endDateTime: combineDayJSToUnix(
      data.eventDate as Dayjs,
      data.endDateTime as Dayjs
    ),
    status: "ACTIVE",
    eventOrganizerName: userInfo.name,
    eventOrganizerTitle: userInfo.jobTitle,
    eventOrganizerEmail: userInfo.email,
    participantEmail: "NA",
  });

  const onSubmit: SubmitHandler<CreateEventForm> = async (data) => {
    setLoading(true);
    if (data.eventImageFile) {
      const options = {
        maxSizeMB: 3.0, // (max file size in MB)
        maxWidthOrHeight: 1920, // compress the image to this maximum width or height
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(
          data.eventImageFile as File,
          options
        );
        const base64Image = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
        const newEventObj = getEventObj(data, base64Image);
        await createEvent(newEventObj);
      } catch (error) {
        console.log(error);
      }
    } else {
      const newEventObj = getEventObj(data, "");
      await createEvent(newEventObj);
    }
  };

  return (
    <>
      <ClearFormModal
        open={showClearFormModal}
        onClose={() => {
          setShowClearFormModal(false);
        }}
        reset={formMethods.reset}
      />
      <PageContainer>
        {createEventError ? (
          <APIError
            onClick={() => {
              setCreateEventError(false);
            }}
          />
        ) : (
          <Styled.PaddedContainer>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Styled.HeaderStack
                  direction={{ mobile: "column", desktop: "row" }}
                  width="100%"
                >
                  <Styled.HeaderText variant="h1" gutterBottom>
                    Create An Event
                  </Styled.HeaderText>
                  <Styled.SignUpCheckboxContainer>
                    <Typography> Sign me up for this event</Typography>
                    <Checkbox
                      name="signUpCreator"
                      control={formMethods.control}
                      defaultChecked
                    />
                  </Styled.SignUpCheckboxContainer>
                </Styled.HeaderStack>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Details </Typography>
                  <Input
                    name={"title"}
                    control={formMethods.control}
                    label={"Event Title"}
                    required
                    placeholder="Enter title..."
                    fullWidth
                  />
                  <Input
                    name={"description"}
                    control={formMethods.control}
                    label={"Event Description"}
                    required
                    placeholder="Enter details..."
                    fullWidth
                    multiline
                  />
                  <Styled.InputStack
                    direction={{ mobile: "column", desktop: "row" }}
                    spacing={theme.spacing(3)}
                    width="100%"
                  >
                    <DatePickerInput
                      name={"eventDate"}
                      control={formMethods.control}
                      label="Event Date"
                      required
                    />

                    <TimeRangePicker
                      startName={"startDateTime"}
                      endName={"endDateTime"}
                      required
                      control={formMethods.control}
                    />
                  </Styled.InputStack>
                  <Styled.InputStack
                    direction={{ mobile: "column", desktop: "row" }}
                    spacing={theme.spacing(3)}
                    width="100%"
                  >
                    <Input
                      name={"dressCode"}
                      control={formMethods.control}
                      label={"Dress Code"}
                      placeholder="Enter title..."
                      select
                      selectOptions={formOptions.dressCode}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <Input
                      name={"admissionCost"}
                      control={formMethods.control}
                      label={"Cost"}
                      placeholder="0.00"
                      inputProps={{ type: "number" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Typography
                            variant="body1"
                            style={{ color: "#000000" }}
                          >
                            $
                          </Typography>
                        </InputAdornment>
                      }
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <FileInput
                      name={"eventImageFile"}
                      control={formMethods.control}
                      label={"Event Image"}
                      placeholder="900x400 png/jpg"
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                  </Styled.InputStack>
                  <Styled.InputStack
                    direction={{ mobile: "column", desktop: "row" }}
                    spacing={theme.spacing(3)}
                    width="100%"
                  >
                    <Input
                      name={"format"}
                      control={formMethods.control}
                      label={"Event Format"}
                      required
                      select
                      selectOptions={formOptions.eventFormats}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <Input
                      name={"businessAreaTags"}
                      control={formMethods.control}
                      label={"Business Area(s)"}
                      required
                      select
                      multiple
                      selectOptions={formOptions.businessAreas}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <Input
                      name={"groupTags"}
                      control={formMethods.control}
                      label={"Group(s)"}
                      required
                      select
                      multiple
                      selectOptions={formOptions.groups}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                  </Styled.InputStack>
                  <Input
                    name={"teamTags"}
                    control={formMethods.control}
                    label={"Team(s)"}
                    required
                    select
                    multiple
                    selectOptions={formOptions.teams}
                    fullWidth
                  />
                  <Box sx={{ marginTop: theme.spacing(2) }}>
                    <Typography
                      variant="body1"
                      sx={{ marginBottom: theme.spacing(1) }}
                    >
                      Would you like to include a Teams link?
                    </Typography>
                    <RadioGroup
                      control={formMethods.control}
                      name={"includeTeamsLink"}
                      label="Include Teams Link?"
                      row
                      options={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                      ]}
                    />
                  </Box>
                  {includeTeamsLink === "yes" && (
                    <Box>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: theme.colors.neutral.darkGray,
                        }}
                      >
                        Copy and paste your Teams meeting link in the provided
                        input below, we will show it in the event details/live
                        event experience.
                      </Typography>
                      <Input
                        name={"teamsLink"}
                        control={formMethods.control}
                        label={"Teams Link"}
                        required
                        sx={{
                          width: "100%",
                        }}
                        forLink
                      />
                    </Box>
                  )}
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Event Feedback </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.colors.neutral.darkGray,
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {
                      "Please generate and paste a link to a survey or feedback form (ex. SparkThink) for attendees to fill out during/after the event."
                    }
                  </Typography>
                  <Input
                    name={"feedbackLink"}
                    control={formMethods.control}
                    label={"Feedback Link"}
                    required
                    placeholder="Enter feedback link..."
                    fullWidth
                    forLink
                  />
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Location </Typography>
                  <Input
                    name={"location"}
                    control={formMethods.control}
                    label={"Location"}
                    required
                    placeholder="Enter details..."
                    fullWidth
                  />

                  <Input
                    name={"transportationParkingDetails"}
                    control={formMethods.control}
                    label={"Transportation & parking details"}
                    placeholder="Enter details..."
                    fullWidth
                    multiline
                  />
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> {"Primary Contact(s)"} </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.colors.neutral.darkGray,
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {
                      "Include the names of the employees responsible for coordinating this event by typing their name and hitting ENTER."
                    }
                  </Typography>
                  <ChipListInput
                    name={"primaryContacts"}
                    control={formMethods.control}
                    label={"Primary Contact(s)"}
                    required
                    placeholder="Start typing a name..."
                    fullWidth
                  />
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Signup Survey </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {
                      "Do you want to ask any specific questions to attendees upon signup for this event?"
                    }
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.colors.neutral.darkGray,
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    {"For example “Do you have any dietary restrictions?”"}
                  </Typography>
                  <RadioGroup
                    control={formMethods.control}
                    name={"includeSignUpSurvey"}
                    label=""
                    row
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                  />
                  {includeSignUpSurvey === "yes" && (
                    <FormBuilder
                      control={formMethods.control}
                      formName="signUpSurvey"
                    />
                  )}
                </Styled.FormSectionContainer>
                {loading ? (
                  <Styled.LoaderContainer>
                    <CircularProgress />
                  </Styled.LoaderContainer>
                ) : (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent={{ mobile: "center", desktop: "end" }}
                    >
                      {hasErrors && (
                        <Styled.ErrorMessage>
                          Please correct all errors before publishing the event
                        </Styled.ErrorMessage>
                      )}
                    </Box>
                    <Styled.FormActionStack
                      direction={{ mobile: "column", desktop: "row-reverse" }}
                      spacing={theme.spacing(4)}
                      width="100%"
                      alignItems="flex-end"
                    >
                      <Styled.ActionButton variant="contained" type="submit">
                        Publish Event
                      </Styled.ActionButton>
                      <Styled.ActionButton
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        type="reset"
                        onClick={() => {
                          setShowClearFormModal(true);
                        }}
                      >
                        Reset Event Form
                      </Styled.ActionButton>
                    </Styled.FormActionStack>
                  </Box>
                )}
              </form>
            </FormProvider>
          </Styled.PaddedContainer>
        )}
      </PageContainer>
    </>
  );
};

export default CreateEventPage;
