import * as Styled from "./PastCreatedPage.styles";
import * as XLSX from "xlsx";

import {
  APIError,
  EventCard,
  PageContainer,
  ScrollToTopFAB,
} from "../../components";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import {
  EventDetailsModal,
  EventEditedModal,
  NewEventCreatedModal,
} from "../../modals";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { CLEAR_NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DownloadIcon from "@mui/icons-material/Download";
import { Event } from "../../types/event";
import { EventMetrics } from "../../types/metrics";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import { GET_EVENT_METRICS } from "../../services/endpoints";
import { Participant } from "../../types/participant";
import { RootState } from "../../store/store";
import axiosAPI from "../../services/axios";
import { clearEvent } from "../../store/reducers/EditEventReducer/EditEventReducer";
import { createSelector } from "reselect";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getUserInfo } from "../../store/actions/userActions";
import { isCuid } from "cuid";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const PastCreatedPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const eventIdFromQueryParam = queryParams.get("eventId");

  const userEmail = useSelector((state: RootState) => state.user.email);
  const userName = useSelector((state: RootState) => state.user.name);

  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [downloadExcelFailed, setDownloadExcelFailed] = useState(false);

  const fetchEventMetrics = async (eventId: string): Promise<EventMetrics> => {
    try {
      const response = await axiosAPI.request({
        url: GET_EVENT_METRICS(eventId),
        method: "GET",
      });

      const metrics = response.data.result;
      return metrics;
    } catch (error) {
      console.error("Error fetching event metrics:", error);
      throw error; // Propagate the error to be caught by the calling function
    }
  };

  const processEventMetrics = (
    eventMetrics: EventMetrics
  ): Partial<EventMetrics> => {
    const {
      signedUp,
      unsignedUp,
      attended,
      didNotAttend,
      attendedWithoutSignup,
      participants,
      ...rest
    } = eventMetrics;
    return rest;
  };

  const createWorkbook = (
    combinedEventMetrics: Partial<EventMetrics>[],
    eventsData: {
      eventMetrics: Partial<EventMetrics>;
      participants: Participant[];
    }[]
  ): XLSX.WorkBook => {
    const workbook = XLSX.utils.book_new();

    // Convert Unix timestamps to readable dates and sort by startTime and endTime
    const transformedMetrics = combinedEventMetrics
      .map((metric) => ({
        ...metric,
        startTime: metric.startTime
          ? new Date(metric.startTime * 1000).toLocaleString()
          : "",
        endTime: metric.endTime
          ? new Date(metric.endTime * 1000).toLocaleString()
          : "",
        averageCheckInTime: metric.averageCheckInTime
          ? new Date(metric.averageCheckInTime * 1000).toLocaleString()
          : "",
      }))
      .sort((a, b) => {
        if (a.startTime && b.startTime) {
          if (a.startTime !== b.startTime) {
            return (
              new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
            );
          }
        }
        if (a.endTime && b.endTime) {
          return new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
        }
        return 0;
      });

    // Create CombinedEventMetrics sheet
    const combinedWorksheet = XLSX.utils.json_to_sheet(transformedMetrics, {
      header:
        transformedMetrics.length > 0 ? Object.keys(transformedMetrics[0]) : [],
    });
    XLSX.utils.book_append_sheet(
      workbook,
      combinedWorksheet,
      "Attendance Metrics Summary"
    );

    // Create sheet for each event with participants
    eventsData.forEach(({ eventMetrics, participants }) => {
      const sheetName = eventMetrics.eventId;
      const worksheetData = participants.map((participant) => ({
        ...participant,
        signedUpAt: participant.signedUpAt
          ? new Date(participant.signedUpAt * 1000).toLocaleString()
          : "",
        checkedInAt: participant.checkedInAt
          ? new Date(participant.checkedInAt * 1000).toLocaleString()
          : "",
      }));
      const headers =
        worksheetData.length > 0
          ? Object.keys(worksheetData[0])
          : [
              "participantEmail",
              "participantName",
              "participantTitle",
              "signedUp",
              "signedUpAt",
              "unsignedUp",
              "unsignedUpAt",
              "checkedIn",
              "checkedInAt",
              "leftEvent",
              "leftEventAt",
              "imagesUploaded",
            ];
      const worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        header: headers,
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    return workbook;
  };
  const createAndDownloadExcel = async () => {
    setDownloadingExcel(true);
    setDownloadExcelFailed(false);

    let combinedEventMetrics: Partial<EventMetrics>[] = [];
    let eventsData: {
      eventMetrics: Partial<EventMetrics>;
      participants: Participant[];
    }[] = [];

    try {
      await Promise.all(
        pastCreatedEvents.map(async (event) => {
          try {
            const eventMetrics = await fetchEventMetrics(event.eventId);
            const participants = eventMetrics.participants || [];
            const processedMetrics = processEventMetrics(eventMetrics);
            combinedEventMetrics.push(processedMetrics);
            eventsData.push({ eventMetrics: processedMetrics, participants });
          } catch (error) {
            setDownloadExcelFailed(true);
            console.error("Error processing event:", error);
          }
        })
      );

      if (!downloadExcelFailed) {
        const workbook = createWorkbook(combinedEventMetrics, eventsData);
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const filename = `${userName} Event Metrics - ${timestamp}.xlsx`;
        XLSX.writeFile(workbook, filename);
      }
    } catch (error) {
      setDownloadExcelFailed(true);
      console.error("Error creating and downloading Excel:", error);
    } finally {
      setDownloadingExcel(false);
    }
  };

  // Split up useSelector calls
  const newEventState = useSelector((state: RootState) => state.newEvent);
  const editEventState = useSelector((state: RootState) => state.editEvent);

  const selectPastCreatedEvents = createSelector(
    (state: RootState) => state.userEvents.created,
    (createdEvents) => {
      const pastCreatedEvents = createdEvents.filter(
        (event) => event.endDateTime < Math.floor(Date.now() / 1000)
      );
      return pastCreatedEvents.sort((a, b) => b.endDateTime - a.endDateTime);
    }
  );

  // Use the selector in your component
  const pastCreatedEvents = useSelector(selectPastCreatedEvents);

  const userEventsLoading = useSelector(
    (state: RootState) => state.userEvents.loading
  );
  const userEventsSuccess = useSelector(
    (state: RootState) => state.userEvents.success
  );
  const userEventsError = useSelector(
    (state: RootState) => state.userEvents.error
  );

  const [eventDetailsId, setEventDetailsId] = useState("");
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showNewEventCreatedModal, setShowNewEventCreatedModal] =
    useState(false);
  const [showEventEditedModal, setShowEventEditedModal] = useState(false);
  useEffect(() => {
    if (eventIdFromQueryParam && isCuid(eventIdFromQueryParam)) {
      setEventDetailsId(eventIdFromQueryParam);
      setShowEventDetailsModal(true);
    }
  }, [eventIdFromQueryParam]);

  useEffect(() => {
    // Close the modal when the eventId query parameter is not present
    if (!searchParams.has("eventId")) {
      setShowEventDetailsModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setShowNewEventCreatedModal(newEventState.eventCreated);
  }, [newEventState.eventCreated]);

  useEffect(() => {
    setShowEventEditedModal(editEventState.eventEdited);
  }, [editEventState.eventEdited]);

  const handleCloseEventDetailsModal = useCallback(() => {
    navigate("/manage/past", { replace: true });
    setShowEventDetailsModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNewEventModal = useCallback(() => {
    dispatch({ type: CLEAR_NEW_EVENT_CREATED });
    setShowNewEventCreatedModal(false);
  }, [dispatch]);

  const handleCloseEventEditedModal = useCallback(() => {
    dispatch(clearEvent());
    setShowEventEditedModal(false);
  }, [dispatch]);

  return (
    <PageContainer>
      <NewEventCreatedModal
        open={showNewEventCreatedModal}
        onClose={handleCloseNewEventModal}
      />
      <EventEditedModal
        open={showEventEditedModal}
        onClose={handleCloseEventEditedModal}
      />
      <EventDetailsModal
        eventId={eventDetailsId}
        open={showEventDetailsModal}
        onClose={handleCloseEventDetailsModal}
      />
      <ScrollToTopFAB />
      <Styled.PaddedContainer>
        <ButtonBase
          onClick={() => {
            navigate("/manage");
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(4),
            cursor: "pointer",
            transition: "opacity 0.3s ease",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          <ChevronLeftIcon
            color="secondary"
            sx={{ marginRight: theme.spacing(1) }}
          />
          <Typography
            variant="modalSubtitle"
            sx={{
              color: theme.colors.primary.slalomDarkBlue,
            }}
          >
            Back to home
          </Typography>
        </ButtonBase>
        {userEventsSuccess ? (
          <Styled.SubtitleContainer>
            <Styled.SubtitleStackContainer
              direction={{
                mobile: "column-reverse",
                tablet: "row",
                desktop: "row",
              }}
              spacing={2}
            >
              <Styled.SubtitleTitle variant="h3">
                {pastCreatedEvents.length === 0
                  ? "My Past Created Events"
                  : `My Past Created Events (${pastCreatedEvents.length})`}
              </Styled.SubtitleTitle>
              <Styled.SubtitleActionContainer>
                <Styled.SubtitleActionText
                  variant="modalSubtitle"
                  onClick={() => {
                    if (!userEventsLoading || !downloadingExcel) {
                      createAndDownloadExcel();
                    }
                  }}
                  style={{
                    color: `${
                      downloadExcelFailed
                        ? theme.colors.action.failure
                        : theme.colors.primary.slalomDarkBlue
                    }`,
                  }}
                >
                  {downloadingExcel ? (
                    <CircularProgress
                      size={20}
                      color="secondary"
                      style={{ marginRight: theme.spacing(1) }}
                    />
                  ) : downloadExcelFailed ? (
                    <FileDownloadOffIcon
                      color="error"
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: theme.spacing(1),
                      }}
                    />
                  ) : (
                    <DownloadIcon
                      color="secondary"
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: theme.spacing(1),
                      }}
                    />
                  )}
                  {`${
                    downloadExcelFailed
                      ? "Failed to Download CSV Report"
                      : "Download CSV Report"
                  }`}
                </Styled.SubtitleActionText>
              </Styled.SubtitleActionContainer>
            </Styled.SubtitleStackContainer>
            <Styled.SubtitleText variant="body2">
              {pastCreatedEvents.length > 0 &&
                "Here are all of the past events that you’ve created. Select an event to view event details."}
            </Styled.SubtitleText>
          </Styled.SubtitleContainer>
        ) : (
          <Styled.SubtitleContainerSkeleton />
        )}
        {userEventsError ? (
          <APIError
            onClick={() => {
              dispatch(getUserInfo());
              dispatch(getAllUserEvents(userEmail));
            }}
          />
        ) : pastCreatedEvents.length === 0 && userEventsSuccess ? (
          <Styled.NoPastEventsContainer>
            <Box>
              <Styled.CalendarIcon />
              <Styled.NoPastEventsText variant="h1">
                No past created events
              </Styled.NoPastEventsText>
              <Styled.NoPastEventsText>
                You have no past created events
              </Styled.NoPastEventsText>
            </Box>
          </Styled.NoPastEventsContainer>
        ) : (
          <Styled.ScrollContainer>
            <Grid
              container
              spacing={3}
              columns={{
                mobile: 4,
                tablet: 8,
                desktop: 12,
              }}
            >
              {!userEventsSuccess
                ? Array.from({ length: 3 }, (_, index) => (
                    <Grid key={index} item mobile={4} tablet={4} desktop={4}>
                      <Styled.SkeletonCardContainer>
                        <Styled.SkeletonCardImage
                          variant="rectangular"
                          animation="wave"
                        />
                        <Styled.SkeletonCardText
                          variant="text"
                          animation="wave"
                        />
                        <Styled.SkeletonCardDescription
                          variant="rectangular"
                          animation="wave"
                        />
                      </Styled.SkeletonCardContainer>
                    </Grid>
                  ))
                : pastCreatedEvents.map((event: Event) => (
                    <Grid
                      key={event.eventId}
                      item
                      mobile={4}
                      tablet={4}
                      desktop={4}
                    >
                      <EventCard
                        {...event}
                        onClick={() => {
                          navigate(
                            `${location.pathname}?eventId=${event.eventId}`,
                            { replace: true }
                          );
                          setEventDetailsId(event.eventId);
                          setShowEventDetailsModal(true);
                        }}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Styled.ScrollContainer>
        )}
      </Styled.PaddedContainer>
    </PageContainer>
  );
};

export default PastCreatedPage;
