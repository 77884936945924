import { UserEventsState } from "../../../types/redux";
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { getAllUserEvents } from "../../actions/userEventsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  created: [],
  signedUp: [],
  attended: [],
} as UserEventsState;

const eventsSlice = createSlice({
  name: "userEvents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUserEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      if (!_.isEqual(state.created, action.payload.result)) {
        state.created = action.payload.result.created;
      }
      if (!_.isEqual(state.signedUp, action.payload.result)) {
        state.signedUp = action.payload.result.signedUp;
      }
      if (!_.isEqual(state.attended, action.payload.result)) {
        state.attended = action.payload.result.attended;
      }
    });
    builder.addCase(getAllUserEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUserEvents.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default eventsSlice.reducer;
