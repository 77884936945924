import * as Styled from "./Header.styles";

import {
  Box,
  IconButton,
  InputBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { GatherLogo, LogoDivider, SlalomLogoBlack } from "../../assets";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";

import AvatarMenu from "../AvatarMenu/AvatarMenu";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import SearchIcon from "@mui/icons-material/Search";
import SearchMenu from "../SearchMenu/SearchMenu";
import { UserState } from "../../types/redux";
import { clearResults } from "../../store/reducers/SearchReducer/SearchReducer";
import { searchEvents } from "../../store/actions/searchActions";
import { useAppDispatch } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

interface Page {
  label: string;
  path: string;
}

enum DrawerType {
  None,
  Search,
  Avatar,
  Menu,
}

const Header = () => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [activeDrawer, setActiveDrawer] = useState(DrawerType.None);

  const handleDrawerOpen = (drawerType: DrawerType) => {
    setActiveDrawer(drawerType);
  };

  const handleDrawerClose = () => {
    setActiveDrawer(DrawerType.None);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const userState: UserState = useSelector((state: any) => state.user);

  const [inputValue, setInputValue] = useState("");
  const [filter, setFilter] = useState("ACTIVE");
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const isDesktop = useMediaQuery((theme: any) =>
    theme.breakpoints.up("desktop")
  );

  const renderLogo = () => {
    return (
      <>
        <Link to={"/events"}>
          <img src={GatherLogo} alt="gather-logo" />
        </Link>
        <img src={LogoDivider} alt="logo-divider" />
        <Link to={"/events"}>
          <img src={SlalomLogoBlack} alt="slalom-logo" />
        </Link>
      </>
    );
  };

  useEffect(() => {
    if (inputValue !== "") {
      dispatch(searchEvents({ term: inputValue, filter }));
    }
  }, [inputValue, filter, dispatch]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const term = event.target.value;
      setInputValue(term);
      dispatch(searchEvents({ term, filter }));

      if (term && activeDrawer !== DrawerType.Search) {
        // Open the drawer only if it's not already open and the input has a value
        handleDrawerOpen(DrawerType.Search);
      } else if (!term && isDesktop) {
        // Close the drawer if input is empty and on desktop
        handleDrawerClose();
      }
    },
    [dispatch, activeDrawer, isDesktop, filter]
  );

  useEffect(() => {
    if (activeDrawer === DrawerType.Search && inputValue) {
      // Use setTimeout to delay the refocusing of the input field
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      // Ensure the input loses focus when closing the drawer
      inputRef.current?.blur();
    }
  }, [activeDrawer, inputValue]);

  const clearInput = useCallback(() => {
    setInputValue("");
    dispatch(clearResults());
  }, [dispatch]);

  useEffect(() => {
    if (
      isDesktop &&
      (activeDrawer === DrawerType.Menu || activeDrawer === DrawerType.Avatar)
    ) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  useEffect(() => {
    if (userState.role === "ADMIN" && !userState.loading && userState.success) {
      setIsUserAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.role, userState.loading, userState.success]);

  const pages: Page[] = [
    { label: "Home", path: "/events" },
    { label: "Manage Events", path: "/manage" },
  ];

  return (
    <Styled.HeaderContainer>
      {isUserAdmin ? (
        isDesktop ? (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {pages?.map((page: any, index: any) => (
              <Link
                key={index}
                to={page.path}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  handleDrawerClose();
                  setTimeout(() => {
                    if (inputRef.current) {
                      inputRef.current.blur(); // Remove focus from the input
                    }
                  }, 0);
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "black",
                    fontFamily: "Slalom Sans Bold",
                    marginRight: theme.spacing(1),
                    textAlign: "center",
                    transition: "background-color 0.5s ease, padding 0.5s ease",
                    backgroundColor:
                      location.pathname === page.path
                        ? theme.colors.secondary.chartreuse
                        : "transparent",
                    padding:
                      location.pathname === page.path
                        ? `${theme.spacing(2)} ${theme.spacing(3)}`
                        : `${theme.spacing(1)} ${theme.spacing(1)}`,
                    borderRadius: theme.spacing(4),
                  }}
                >
                  {page.label}
                </Typography>
              </Link>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
              justifyContent: "flex-start",
              marginRight: theme.spacing(1),

              flex: "0 0 auto",
            }}
          >
            <IconButton
              onClick={() => {
                if (activeDrawer === DrawerType.Menu) {
                  handleDrawerClose();
                } else {
                  handleDrawerOpen(DrawerType.Menu);
                }
              }}
            >
              <MenuIcon />
            </IconButton>
            {renderLogo()}
          </Box>
        )
      ) : isDesktop ? (
        <Box />
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            alignItems: "center",
          }}
        >
          {renderLogo()}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isDesktop && renderLogo()}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!isDesktop ? (
          activeDrawer === DrawerType.Search ? (
            <IconButton
              onClick={handleDrawerClose}
              style={{
                marginRight: theme.spacing(2),
              }}
            >
              <CloseIcon style={{ color: theme.colors.neutral.darkGray }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleDrawerOpen(DrawerType.Search)}
              style={{
                marginRight: theme.spacing(2),
              }}
            >
              <SearchIcon style={{ color: theme.colors.neutral.darkGray }} />
            </IconButton>
          )
        ) : (
          <Styled.SearchBar>
            <Styled.SearchIconWrapper>
              <SearchIcon style={{ color: theme.colors.neutral.darkGray }} />
            </Styled.SearchIconWrapper>
            <InputBase
              inputProps={{
                ref: inputRef,
                "aria-label": "search",
              }}
              placeholder="Find an event…"
              value={inputValue}
              onChange={handleInputChange}
              sx={{
                color: "black",
                width: "100%",
                paddingRight: "2rem",
                "& .MuiInputBase-input": {
                  padding: theme.spacing(1, 3, 1, 0),
                  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                },
              }}
              style={{ position: "relative", zIndex: 9998 }}
            />
            {inputValue && (
              <Styled.ClearIconWrapper
                onClick={() => {
                  clearInput();
                  handleDrawerClose();
                  setTimeout(() => {
                    if (inputRef.current) {
                      inputRef.current.blur(); // Remove focus from the input
                    }
                  }, 0);
                }}
                style={{ zIndex: 9999 }}
              >
                <ClearIcon
                  style={{
                    color: "black",
                  }}
                />
              </Styled.ClearIconWrapper>
            )}
          </Styled.SearchBar>
        )}
        {isDesktop ? (
          <PopupState variant="popover" popupId="popup-popover">
            {(popupState) => (
              <Box display="flex">
                <Styled.AvatarButtonDesktop {...bindHover(popupState)}>
                  <Styled.Avatar
                    alt={`user-avatar`}
                    src={``}
                    {...bindHover(popupState)}
                  />
                </Styled.AvatarButtonDesktop>
                <HoverPopover
                  {...bindPopover(popupState)}
                  disableAutoFocus
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: theme.spacing(1),
                      boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
                      padding: "none",
                      minWidth: "350px",
                      backgroundColor: theme.colors.neutral.white,
                    },
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: 50,
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  disableRestoreFocus
                >
                  <AvatarMenu user={userState} />
                </HoverPopover>
              </Box>
            )}
          </PopupState>
        ) : (
          <Styled.AvatarButtonMobile
            onClick={() => {
              if (activeDrawer === DrawerType.Avatar) {
                handleDrawerClose();
              } else {
                handleDrawerOpen(DrawerType.Avatar);
              }
            }}
          >
            <Styled.Avatar alt={`user-avatar`} src={``} />
          </Styled.AvatarButtonMobile>
        )}
      </Box>
      <Drawer
        anchor="top"
        open={activeDrawer === DrawerType.Menu}
        onClose={handleDrawerClose}
        sx={{
          "&.MuiDrawer-root .MuiDrawer-paper": {
            marginTop: 100 - scrollPosition + "px",
          },
          zIndex: theme.zIndex.drawer,
        }}
      >
        <NavigationMenu pages={pages} toggleDrawer={handleDrawerClose} />
      </Drawer>
      <Drawer
        anchor="top"
        open={activeDrawer === DrawerType.Avatar}
        onClose={handleDrawerClose}
        sx={{
          "&.MuiDrawer-root .MuiDrawer-paper": {
            marginTop: 100 - scrollPosition + "px",
          },
          zIndex: theme.zIndex.drawer,
        }}
      >
        <AvatarMenu user={userState} />
      </Drawer>
      <Drawer
        anchor="top"
        open={activeDrawer === DrawerType.Search}
        onClose={() => {
          handleDrawerClose();
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.blur(); // Remove focus from the input
            }
          }, 0);
        }}
        disableEnforceFocus
        sx={{
          "&.MuiDrawer-root .MuiDrawer-paper": {
            marginTop: 100 - scrollPosition + "px",
          },
          zIndex: theme.zIndex.drawer,
        }}
      >
        <SearchMenu
          inputValue={inputValue}
          setInputValue={setInputValue}
          onInputChange={handleInputChange}
          filter={filter}
          setFilter={setFilter}
          handleDrawerClose={handleDrawerClose}
        />
      </Drawer>
    </Styled.HeaderContainer>
  );
};

export default Header;
