import * as Styled from "./EventSignUpsModal.styles";

import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SignUp, SurveyAnswer } from "../../types/signup";
import { useCallback, useState } from "react";

import BaseModal from "../BaseModal/BaseModal";
import CloseIcon from "@mui/icons-material/Close";
import { Contact } from "../../components";
import { RootState } from "../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

type EventSignUpsModalProps = {
  open: boolean;
  onClose: () => void;
  navigateToEvent?: () => void;
};

const selectSignupsLoading = (state: RootState) => state.event.signups.loading;
const selectSignups = (state: RootState) => state.event.signups.data;

const selectUserAndEvents = createSelector(
  [selectSignupsLoading, selectSignups],
  (signupsLoading, signups) => ({ signupsLoading, signups })
);

const EventSignUpsModal = ({ open, onClose }: EventSignUpsModalProps) => {
  const theme = useTheme();

  const [selectedSignupEmail, setSelectedSignupEmail] = useState<string | null>(
    null
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const { signups } = useSelector(selectUserAndEvents);

  const handleContactClick = useCallback((signupEmail: string) => {
    setSelectedSignupEmail((prevEmail) =>
      prevEmail === signupEmail ? null : signupEmail
    );
  }, []);

  const renderSurveyAnswers = (signup: SignUp) => {
    if (
      !signup.signUpSurveySubmission ||
      signup.signUpSurveySubmission.length === 0
    ) {
      return <Typography>No survey responses available.</Typography>;
    }

    return signup.signUpSurveySubmission.map(
      (answer: SurveyAnswer, index: number) => (
        <Box key={index}>
          <Typography
            variant="body2"
            sx={{
              color: theme.colors.secondary.darkPurple,
              marginBottom: theme.spacing(1),
            }}
          >
            {answer.type}
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: theme.spacing(1) }}>
            {answer.question}
          </Typography>
          <Typography sx={{ marginBottom: theme.spacing(1) }}>
            {answer.answer}
          </Typography>
          {index !== signup.signUpSurveySubmission!.length - 1 && (
            <Divider
              sx={{
                width: "100%",
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(4),
                backgroundColor: theme.colors.secondary.purple,
              }}
            />
          )}
        </Box>
      )
    );
  };

  return (
    <BaseModal open={open} onClose={onClose} fullScreenTakeover padding="0px">
      <>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">{`Event sign ups (${signups.length})`}</Typography>
        </Styled.ModalTitleContainer>
        <Styled.HorizontalStack direction="row">
          <Styled.LeftColumn isSelected={!!selectedSignupEmail}>
            <Styled.InnerLeftColumn>
              <Typography variant="h3">{`Employee (${signups.length})`}</Typography>
              {signups.map((signup: SignUp, index: number) => (
                <>
                  <Contact
                    key={signup.participantEmail}
                    size={36}
                    displayChevron={isMobile}
                    name={signup.participantName}
                    onClick={() => handleContactClick(signup.participantEmail)}
                    isSelected={signup.participantEmail === selectedSignupEmail}
                    selectedBorderColor={theme.colors.secondary.purple}
                    selectedBackgroundColor={`${theme.colors.secondary.purple}1A`}
                    selectedBoxShadowColor={
                      theme.colors.action.hoverShadowPurple
                    }
                  />
                  {index !== signups.length - 1 && (
                    <Divider
                      sx={{
                        width: "100%",
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        backgroundColor: theme.colors.action.modalClose,
                      }}
                    />
                  )}
                </>
              ))}
            </Styled.InnerLeftColumn>
          </Styled.LeftColumn>
          <Styled.RightColumn isSelected={!!selectedSignupEmail}>
            {selectedSignupEmail && isMobile && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(2),
                }}
                onClick={() => setSelectedSignupEmail(null)}
              >
                <CloseIcon />
              </IconButton>
            )}
            <Styled.InnerRightColumn>
              {selectedSignupEmail ? (
                <>
                  <Typography
                    variant="h3"
                    sx={{ marginBottom: theme.spacing(3) }}
                  >
                    Survey Response
                  </Typography>
                  {signups
                    .filter(
                      (signup: SignUp) =>
                        signup.participantEmail === selectedSignupEmail
                    )
                    .map((signup: SignUp) => (
                      <Styled.SurveyAnswerContainer
                        key={signup.participantEmail}
                      >
                        {renderSurveyAnswers(signup)}
                      </Styled.SurveyAnswerContainer>
                    ))}
                </>
              ) : (
                <Typography variant="body1">
                  Select a signup to see details
                </Typography>
              )}
            </Styled.InnerRightColumn>
          </Styled.RightColumn>
        </Styled.HorizontalStack>
      </>
    </BaseModal>
  );
};

export default EventSignUpsModal;
