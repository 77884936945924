import { Dayjs } from "dayjs";

export const combineDayJSToUnix = (eventDate: Dayjs, time: Dayjs) => {
  const combinedDate = new Date(
    `${eventDate.format("YYYY-MM-DD")}T${time.format("HH:mm:ss")}`
  );
  return Math.floor(combinedDate.getTime() / 1000);
};

export const unixDateDiff = (d1: number, d2: number) => {
  return d1 - d2;
};

export const getMonthAndDayFromMilliseconds = (time: number) => {
  const date = new Date(time * 1000);
  return date.toLocaleString("default", {
    month: "short",
    day: "numeric",
  });
};

export const getHourFromMilliseconds = (time: number) => {
  var t = new Date(time * 1000).getHours();
  t = t % 12;
  return (t ? t : 12).toString();
};

export const getMinutesFromMilliseconds = (time: number) => {
  const date = new Date(time * 1000);
  let minutes = date.getMinutes();

  minutes = (Math.round(minutes / 15) * 15) % 60;

  return minutes.toString().padStart(2, "0");
};

export const nthNumber = (time: number) => {
  const dT = parseInt(
    new Date(time * 1000).toLocaleString("default", {
      day: "2-digit",
    })
  );
  return ["ST", "ND", "RD"][((((dT + 90) % 100) - 10) % 10) - 1] || "TH";
};

export const unixToHourAndMinutes = (unixTime: number): string => {
  const date = new Date(unixTime * 1000); // Convert unix time to JavaScript time
  let hours = date.getHours();
  let minutes = date.getMinutes().toString();
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes.length === 1 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes;
  strTime = strTime.endsWith(":00") ? strTime.slice(0, -3) : strTime; // Remove ':00' from the time if it ends with it
  return strTime;
};

export const getAmOrPm = (unixTime: number): string => {
  const date = new Date(unixTime * 1000); // Convert unix time to JavaScript time
  const hours = date.getHours();
  return hours >= 12 ? "PM" : "AM";
};

export const dateToUnix = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
};

export const toBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
