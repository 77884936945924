import * as Styled from "./ManageEventsPage.styles";

import {
  APIError,
  EventCard,
  PageContainer,
  RefreshEventsIconButton,
  ScrollToTopFAB,
} from "../../components";
import { Box, Button, Grid, useTheme } from "@mui/material";
import {
  EventDetailsModal,
  EventEditedModal,
  NewEventCreatedModal,
} from "../../modals";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { CLEAR_NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import { Event } from "../../types/event";
import { RootState } from "../../store/store";
import { clearEvent } from "../../store/reducers/EditEventReducer/EditEventReducer";
import { createSelector } from "reselect";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getUserInfo } from "../../store/actions/userActions";
import { isCuid } from "cuid";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const ManageEventsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const eventIdFromQueryParam = queryParams.get("eventId");

  const userRole = useSelector((state: RootState) => state.user.role);
  const userEmail = useSelector((state: RootState) => state.user.email);

  // Split up useSelector calls
  const newEventState = useSelector((state: RootState) => state.newEvent);
  const editEventState = useSelector((state: RootState) => state.editEvent);
  const selectUserCreatedEvents = (state: RootState) =>
    state.userEvents.created;

  const selectFutureUserCreatedEvents = createSelector(
    [selectUserCreatedEvents],
    (userEvents) =>
      userEvents.filter(
        (event: Event) => event.endDateTime > Math.floor(Date.now() / 1000)
      )
  );

  const userCreatedEvents = useSelector(selectFutureUserCreatedEvents);
  const userEventsSuccess = useSelector(
    (state: RootState) => state.userEvents.success
  );
  const userEventsError = useSelector(
    (state: RootState) => state.userEvents.error
  );

  const [eventDetailsId, setEventDetailsId] = useState("");
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showNewEventCreatedModal, setShowNewEventCreatedModal] =
    useState(false);
  const [showEventEditedModal, setShowEventEditedModal] = useState(false);
  useEffect(() => {
    if (eventIdFromQueryParam && isCuid(eventIdFromQueryParam)) {
      setEventDetailsId(eventIdFromQueryParam);
      setShowEventDetailsModal(true);
    }
  }, [eventIdFromQueryParam]);

  useEffect(() => {
    // Close the modal when the eventId query parameter is not present
    if (!searchParams.has("eventId")) {
      setShowEventDetailsModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setShowNewEventCreatedModal(newEventState.eventCreated);
  }, [newEventState.eventCreated]);

  useEffect(() => {
    setShowEventEditedModal(editEventState.eventEdited);
  }, [editEventState.eventEdited]);

  const handleCloseEventDetailsModal = useCallback(() => {
    navigate("/manage", { replace: true });
    setShowEventDetailsModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNewEventModal = useCallback(() => {
    dispatch({ type: CLEAR_NEW_EVENT_CREATED });
    setShowNewEventCreatedModal(false);
  }, [dispatch]);

  const handleCloseEventEditedModal = useCallback(() => {
    dispatch(clearEvent());
    setShowEventEditedModal(false);
  }, [dispatch]);

  return (
    <PageContainer>
      <NewEventCreatedModal
        open={showNewEventCreatedModal}
        onClose={handleCloseNewEventModal}
      />
      <EventEditedModal
        open={showEventEditedModal}
        onClose={handleCloseEventEditedModal}
      />
      <EventDetailsModal
        eventId={eventDetailsId}
        open={showEventDetailsModal}
        onClose={handleCloseEventDetailsModal}
      />
      <ScrollToTopFAB />
      <Styled.PaddedContainer>
        <Styled.HeaderStack
          direction={{ mobile: "column", tablet: "row", desktop: "row" }}
          width="100%"
          spacing={3}
        >
          <Styled.HeaderText variant="h1" gutterBottom>
            Manage Events
          </Styled.HeaderText>
          <Styled.CreateEventActionContainer>
            <RefreshEventsIconButton />
            {userRole === "ADMIN" && (
              <Button
                startIcon={<AddCircleOutlineOutlinedIcon />}
                variant="contained"
                onClick={() => {
                  navigate("/create");
                }}
                sx={{
                  [theme.breakpoints.down("tablet")]: {
                    width: "100%",
                  },
                }}
              >
                Create an Event
              </Button>
            )}
          </Styled.CreateEventActionContainer>
        </Styled.HeaderStack>
        {userEventsSuccess ? (
          <Styled.SubtitleContainer>
            <Styled.SubtitleStackContainer
              direction={{ mobile: "column", tablet: "row", desktop: "row" }}
              spacing={2}
            >
              <Styled.SubtitleTitle variant="h3">
                {userCreatedEvents.length === 0
                  ? "My Created Events"
                  : `My Created Events (${userCreatedEvents.length})`}
              </Styled.SubtitleTitle>
              <Styled.SubtitleActionContainer>
                <Styled.SubtitleActionText
                  variant="modalSubtitle"
                  onClick={() => {
                    navigate("/manage/past");
                  }}
                >
                  View my past created events
                </Styled.SubtitleActionText>
              </Styled.SubtitleActionContainer>
            </Styled.SubtitleStackContainer>
            <Styled.SubtitleText variant="body2">
              {userCreatedEvents.length > 0 &&
                "Here are all of the upcoming events that you’ve created. Select an event to view and edit event details."}
            </Styled.SubtitleText>
          </Styled.SubtitleContainer>
        ) : (
          <Styled.SubtitleContainerSkeleton />
        )}
        {/* UPCOMING EVENTS GRID */}
        {userEventsError ? (
          <APIError
            onClick={() => {
              dispatch(getUserInfo());
              dispatch(getAllUserEvents(userEmail));
            }}
          />
        ) : userCreatedEvents.length === 0 && userEventsSuccess ? (
          <Styled.NoUpcomingEventsContainer>
            <Box>
              <Styled.CalendarIcon />
              <Styled.NoUpcomingEventsText variant="h1">
                No Upcoming Events
              </Styled.NoUpcomingEventsText>
              <Styled.NoUpcomingEventsText>
                There are no upcoming events that you have created.
              </Styled.NoUpcomingEventsText>
            </Box>
          </Styled.NoUpcomingEventsContainer>
        ) : (
          <Styled.ScrollContainer>
            <Grid
              container
              spacing={3}
              columns={{
                mobile: 4,
                tablet: 8,
                desktop: 12,
              }}
            >
              {!userEventsSuccess ? (
                Array.from({ length: 3 }, (_, index) => (
                  <Grid key={index} item mobile={4} tablet={4} desktop={4}>
                    <Styled.SkeletonCardContainer>
                      <Styled.SkeletonCardImage
                        variant="rectangular"
                        animation="wave"
                      />
                      <Styled.SkeletonCardText
                        variant="text"
                        animation="wave"
                      />
                      <Styled.SkeletonCardDescription
                        variant="rectangular"
                        animation="wave"
                      />
                    </Styled.SkeletonCardContainer>
                  </Grid>
                ))
              ) : (
                <>
                  {userCreatedEvents.map((event: Event) => (
                    <Grid
                      key={event.eventId}
                      item
                      mobile={4}
                      tablet={4}
                      desktop={4}
                    >
                      <EventCard
                        {...event}
                        onClick={() => {
                          navigate(
                            `${location.pathname}?eventId=${event.eventId}`,
                            { replace: true }
                          );
                          setEventDetailsId(event.eventId);
                          setShowEventDetailsModal(true);
                        }}
                      />
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Styled.ScrollContainer>
        )}
      </Styled.PaddedContainer>
    </PageContainer>
  );
};

export default ManageEventsPage;
