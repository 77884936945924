import { Box, Fab, Skeleton, Stack, Typography } from "@mui/material";

import { Calendar } from "../../assets";
import { styled } from "@mui/material/styles";

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const HeaderSkeletonContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "90%",
  height: "40px",
}));

export const HeaderSkeletonText = styled(Skeleton)(({ theme }) => ({
  height: "50px",
  width: "180px",
  marginLeft: theme.spacing(1),
}));

export const ScrollContainer = styled(Box)(() => ({
  flexGrow: 1,
  paddingTop: 4,
}));

export const AllEventsText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const InfiniteScrollEndContainer = styled(Box)(() => ({
  textAlign: "center",
  marginTop: 32,
}));

export const RefreshEventsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row-reverse",
  width: "10%",
}));

export const SkeletonCardContainer = styled(Box)(() => ({
  width: "100%",
  height: "354px",
}));

export const SkeletonCardDescription = styled(Skeleton)(() => ({
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  width: "100%",
  height: "100px",
}));

export const SkeletonCardImage = styled(Skeleton)(() => ({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  width: "100%",
  height: "200px",
}));

export const SkeletonCardText = styled(Skeleton)(() => ({
  width: "100%",
  height: "40px",
}));

export const SubtitleContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const SubtitleActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
    flexDirection: "row-reverse",
    marginBottom: 0,
  },
  [theme.breakpoints.up("desktop")]: {
    width: "50%",
    flexDirection: "row-reverse",
    marginBottom: 0,
  },
}));

export const SubtitleActionText = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "opacity 0.3s ease",
  "&:hover": {
    opacity: 0.7,
  },
  color: theme.colors.primary.slalomDarkBlue,
}));

export const SubtitleStackContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(1),
}));

export const SubtitleText = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral.darkGray,
}));

export const SubtitleTitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
    marginBottom: 0,
  },
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
    marginBottom: 0,
  },
}));

export const SubtitleContainerSkeleton = styled(Skeleton)(({ theme }) => ({
  height: "56px",
  width: "100%",
  marginBottom: theme.spacing(3),
}));

export const ScrollToTopFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 30,
  left: "50%",
  marginLeft: "-52.55px",
  boxShadow: `0px 6px 6px 0px ${theme.colors.action.hoverShadow}`,
}));

export const CreateEventActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row-reverse",
  width: "45%",
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
  },
}));

export const HeaderText = styled(Typography)(() => ({
  width: "55%",
}));

export const CalendarIcon = styled(Calendar)(({ theme }) => ({
  width: "20vh",
  height: "auto",
  marginBottom: theme.spacing(2),
}));

export const NoPastEventsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6),
  textAlign: "center",
}));

export const NoPastEventsText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
