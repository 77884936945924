import { EditEventState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";
import { getEvent } from "../../actions/editEventActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
  eventEdited: false,
} as EditEventState;

const eventSlice = createSlice({
  name: "eventToEdit",
  initialState,
  reducers: {
    editEvent: (state) => {
      state.eventEdited = true;
    },
    clearEvent: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.data = action.payload.result;
    });
    builder.addCase(getEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvent.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.data = null;
    });
  },
});

export const { editEvent, clearEvent } = eventSlice.actions;

export default eventSlice.reducer;
