import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  CreateEventPage,
  EditEventPage,
  EventsPage,
  LiveEventPage,
  LoginPage,
  ManageEventsPage,
  NotFoundPage,
  PastCreatedPage,
  PastUserEventsPage,
} from "../../pages";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <EventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage"
          element={
            <ProtectedRoute>
              <ManageEventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <ProtectedRoute showLiveEventIndicator={false}>
              <EditEventPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create"
          element={
            <ProtectedRoute showLiveEventIndicator={false}>
              <CreateEventPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/live/:id"
          element={
            <ProtectedRoute showLiveEventIndicator={false}>
              <LiveEventPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events/past"
          element={
            <ProtectedRoute>
              <PastUserEventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage/past"
          element={
            <ProtectedRoute>
              <PastCreatedPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
