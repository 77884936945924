import * as Styled from "./LiveEventIndicator.styles";

import {
  Box,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getAmOrPm, unixToHourAndMinutes } from "../../utils/utils";

import { Event } from "../../types/event";
import { RootState } from "../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LiveEventIndicator = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;

  const selectUserAndEvents = createSelector(
    (state: RootState) => state.user,
    (state: RootState) => state.userEvents.success,
    (state: RootState) => state.userEvents.signedUp,
    (state: RootState) => state.userEvents.attended,
    (user, userEventSuccess, signedUp, attended) => {
      const currentTime = Math.floor(Date.now() / 1000);
      const merged = [...attended, ...signedUp];
      const eventIds = new Set();
      const commonEventsHappening = merged.filter((event) => {
        if (!eventIds.has(event.eventId)) {
          eventIds.add(event.eventId);
          return true;
        }
        return false;
      });
      const userEventsHappening = commonEventsHappening.filter(
        (event) =>
          event.startDateTime <= currentTime && event.endDateTime > currentTime
      );
      return { user, userEventSuccess, userEventsHappening };
    }
  );

  const { userEventSuccess, userEventsHappening } =
    useSelector(selectUserAndEvents);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <Fade in={userEventSuccess} timeout={500}>
      <Box>
        {userEventSuccess && userEventsHappening.length >= 1 && (
          <Styled.IndicatorContainer direction="column">
            {userEventSuccess &&
              userEventsHappening.map((event: Event, index) => (
                <Box key={event.eventId}>
                  {index !== 0 && (
                    <Box
                      sx={{
                        backgroundColor: theme.colors.action.modalClose,
                        width: "100%",
                        height: "1px",
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                      }}
                    />
                  )}
                  <Stack
                    direction={{ mobile: "column", tablet: "row" }}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        marginRight: theme.spacing(3),
                        marginBottom: !isMobile ? 0 : theme.spacing(1),
                      }}
                    >
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          backgroundColor: theme.colors.secondary.coralRed,
                          marginRight: theme.spacing(1),
                          marginTop: 0.5,
                        }}
                      />
                      <Typography variant="body2">
                        <span style={{ fontFamily: "Slalom Sans Bold" }}>
                          {event.title.length >= 26
                            ? event.title.substring(0, 26) + "... "
                            : event.title}
                        </span>
                        {` started at ${unixToHourAndMinutes(
                          event.startDateTime
                        )}`}
                        <Styled.SuperScript>
                          {getAmOrPm(event.startDateTime)}
                        </Styled.SuperScript>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ ml: !isMobile ? "auto" : 0 }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          marginRight: theme.spacing(3),
                          color: theme.colors.primary.slalomDarkBlue,
                          cursor: "pointer",
                          textDecoration: "underline",
                          transition: "opacity 0.3s",
                          "&:hover": {
                            opacity: 0.7,
                          },
                        }}
                        onClick={() => {
                          navigate(`${currentPath}?eventId=${event.eventId}`);
                        }}
                      >
                        View details
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.colors.primary.slalomDarkBlue,
                          cursor: "pointer",
                          textDecoration: "underline",
                          transition: "opacity 0.3s",
                          "&:hover": {
                            opacity: 0.7,
                          },
                        }}
                        onClick={() => {
                          navigate(`/live/${event.eventId}`);
                        }}
                      >
                        Join event
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              ))}
          </Styled.IndicatorContainer>
        )}
      </Box>
    </Fade>
  );
};

export default LiveEventIndicator;
